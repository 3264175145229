import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { LogisticsInfo } from '../../components';
import styles from './index.less';
import type { CourierInfoStore } from './store';

export const CourierInfoDrawer: React.FC<{ store: CourierInfoStore; }> = observer((props) => {
  const {
    visible,
    onDrawerClose,
    loading,
    courierName,
    courierOrderNo,
    returnSendTime,
    totalNum,
    skuVoList,
    courierInfoList,
  } = props.store;
  return (
    <Drawer
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
      closable={false}
      maskClosable
      onClose={onDrawerClose}
      open={visible}
      width={480}
    >
      <div className={styles.drawerHeader}>
        <span>
          物流信息
        </span>
        <CloseOutlined
          className={styles.closeIcon}
          onClick={onDrawerClose}
        />
      </div>
      <div className={styles.drawerBody}>
        <Spin
          spinning={loading}
          tip="数据加载中..."
          wrapperClassName={styles.spin}
        >
          <LogisticsInfo
            courierInfoList={courierInfoList}
            courierName={courierName}
            courierOrderNo={courierOrderNo}
            sendTime={returnSendTime}
            skuVoList={skuVoList}
          />
        </Spin>
      </div>
    </Drawer>
  );
});

