import { message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { NormalProgramme, request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { OrderGridStore } from '../components/orderGrid/store';
import { api } from './api';
import { FILTER_ITEMS, GRID_COLUMNS, itemHeaderRightContent, ITEM_HEADER_COLUMNS } from './constant';
import { CourierInfoStore } from './courierInfoDrawer/store';
import DownloadOrderModalStore from './downOrderModal/store';
import type { IConfig } from './interface';

export class Store {
  constructor() {
    this.getConfigStatus();
    this.getShopList();
    this.programme.handleSearch();
  }

  public courierInfoStore = new CourierInfoStore({ parent: this });

  public downloadOrderModalStore = new DownloadOrderModalStore(this);

  // 查询售后单
  public querySaleOrder = (page?): Promise<void> => {
    const params = this.programme.filterItems.params;

    // 只要选了售后状态就把tab选中
    this.activeStatus = params.platformRefundStatus ? Number(params.platformRefundStatus) : this.activeStatus;
    const pageInfo = this.orderGridStore.pageInfo;

    // 处理申请时间
    if (params.applyTime) {
      const applyTimeObj = {
        applyStartTime: (params.applyTime as string).split(',')[0],
        applyEndTime: (params.applyTime as string).split(',')[1],
      };
      Object.assign(params, applyTimeObj);
      delete params.applyTime;
    }

    // 增加分页信息
    Object.assign(params, {
      page: page || pageInfo.page,
      pageSize: pageInfo.pageSize,
    });
    this.orderGridStore.loading = true;
    return request<PaginationData>({
      url: api.getAfterSaleOrder,
      method: 'POST',
      data: params,
    }).then((res) => {
      this.orderGridStore.list = res.data.list;
      this.orderGridStore.pageInfo = {
        page: res.data.page,
        pageSize: res.data.pageSize,
        total: res.data.totalCount,
      };
    })
      .finally(() => {
        this.orderGridStore.loading = false;
      });
  };

  // 页码变化
  @action public onPageChange = (page: number, pageSize: number): void => {
    this.orderGridStore.pageInfo = {
      ...this.orderGridStore.pageInfo,
      page,
      pageSize,
    };
    this.querySaleOrder();
  };
  
  @observable public orderGridStore = new OrderGridStore({
    list: [],
    primaryKey: 'afterSaleId',
    detailField: 'afterSaleDetailList',
    detailHeight: 72,
    detailKey: 'afterSaleDetailId',
    itemHeaderColumns: ITEM_HEADER_COLUMNS(this),
    gridColumns: GRID_COLUMNS(this),
    onPageChange: (page: number, pageSize: number) => {
      this.orderGridStore.pageInfo = {
        ...this.orderGridStore.pageInfo,
        page,
        pageSize,
      };
      this.querySaleOrder();
    },
    itemHeaderRightContent,
    showCheckbox: false,
    showRefresh: true,
  });

  @observable public afterSaleStatusList = [];// 顶部售后状态

  @observable public activeStatus = -1;// 选中售后状态

  // 查询方案
  public programme: NormalProgramme = new NormalProgramme({
    filterItems: FILTER_ITEMS(this),
    count: 6,
    handleSearch: () => {
      return this.querySaleOrder(1);
    },
  });

  // 获取状态列表
  @action private getConfigStatus = (): void => {
    request<BaseData<IConfig>>({ url: api.getStatusList }).then((res) => {
      const { afterSaleTypeList, platformRefundStatusList, reasonTypeList } = res.data;
      const platformRefundStatus = this.mapOptions(platformRefundStatusList, 'state', 'describe');
      const afterSaleType = this.mapOptions(afterSaleTypeList, 'state', 'describe');
      const reasonType = this.mapOptions(reasonTypeList, 'state', 'describe');
      this.afterSaleStatusList = platformRefundStatus?.map((v) => {
        v.value = Number(v.value);
        return v;
      });
      this.programme.filterItems.addDict({
        platformRefundStatus,
        afterSaleType,
        reasonType,
      });
    });
  };

  @action public getShopList = (): void => {
    request<PaginationData>({ url: api.getShopList }).then((res) => {
      const shopList = this.mapOptions(res.data, 'shopId', 'shopName');
      this.programme.filterItems.addDict({ shopId: shopList });
    });
  };

  // 处理顶部tab选项变化
  @action public handleTabChange = (key: number): void => {
    this.activeStatus = key;
    this.programme.filterItems.updateFilterItem([
      {
        field: 'platformRefundStatus',
        value: key === -1 ? '' : key.toString(),
      },
    ]);

    // 重置表格组件页码
    this.orderGridStore.pageInfo.page = 1;
    this.programme.handleSearch();
  };

  // 处理选项
  private mapOptions = (data, key: string, val: string) => {
    return data?.map((item) => ({
      label: item[val],
      value: item[key],
    }));
  };
}
