import { Col, Row, Timeline } from 'antd';
import { nanoid } from 'nanoid';
import React from 'react';
import styles from './index.less';

interface ILogisticInfo{
  courierName: string;
  courierOrderNo: string;
  sendTime: string;
  skuVoList: Array<{
    goodsSkuId: number;

    // mallReturnOrderDetailId: number;
    num: number;
    picUrl: string;
  }>;
  courierInfoList: Array<{
    status: string;
    statusCode: number;
    statusTime: string;
    trace: string;
    waybillCode: string;
  }>;
}

// 订单-物流信息
export const LogisticsInfo: React.FC<ILogisticInfo> = (props) => {
  const { courierName, courierOrderNo, sendTime, skuVoList, courierInfoList } = props;
  return (
    <div className={styles.content}>
      <Row
        gutter={[
          0,
          12,
        ]}
      >
        <Col span={6}>
          物流公司:
        </Col>
        <Col span={16}>
          {courierName}
        </Col>
        <Col span={6}>
          运单号:
        </Col>
        <Col span={16}>
          {courierOrderNo}
        </Col>
        <Col span={6}>
          物流公司:
        </Col>
        <Col span={16}>
          {courierName}
        </Col>
        <Col span={6}>
          发货时间:
        </Col>
        <Col span={16}>
          {sendTime}
        </Col>
        <Col span={6}>
          商品信息:
        </Col>
        <Col span={16}>
          <Row gutter={[
            8,
            8,
          ]}
          >
            {Array.isArray(skuVoList) && skuVoList.map((el) => {
              return (
                <Col key={el.goodsSkuId}>
                  <Image
                    num={el.num}
                    url={el.picUrl}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <div className={styles.courierListInfo}>
        <div className={styles.infoTitle}>
          物流信息
        </div>
        <Timeline
          mode="left"
        >
          {Array.isArray(courierInfoList) && courierInfoList.map((el) => {
            return (
              <Timeline.Item
                dot={<Dot color={el.statusCode === 4 ? '#1978FF' : ''}/>}
                key={nanoid()}
                label={el.statusTime}
              >
                {el.trace}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </div>
    </div>
  );
};

const Dot: React.FC<{ color?: string; }> = (props) => {
  return (
    <div
      className={styles.dot}
      style={{ backgroundColor: props.color || '#D3D3D3' }}
    />
  );
};

const Image: React.FC<{ url: string; num: number; }> = (props) => {
  const { url, num } = props;
  return (
    <div
      className={styles.productImg}
    >
      <img
        alt=""
        src={url}
      />
      <div className={styles.goodsCount}>
        {`共${num}件`}
      </div>
    </div>
  );
};
