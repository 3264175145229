import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Radio, Space, Tabs } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const TABS = [
  {
    label: '按时间段下载订单',
    key: 'time',
  },

  // 下期实现
  // {
  //   label: '按平台售后单号下载订单',
  //   key: 'order',
  // },
];

export const DownloadOrderModal = observer((props) => {
  const {
    visible,
    loading,
    btnDisabled,
    formRef,
    closeModal,
    handleOk,
    changeTab,
    tabKey,
    searchValue,
    shopList,
    gridModel,
    changeSearchValue,
    radioShopId,
    changeRadioShop,
    onSearch,
  } = props.store;
  return (
    <Modal
      className={styles.downloadOrderModal}
      closable={false}
      footer={(
        <>
          <Button
            onClick={closeModal}
          >
            取消
          </Button>
          <Button
            disabled={btnDisabled}
            loading={loading}
            onClick={handleOk}
            type="primary"
          >
            开始下载
          </Button>
        </>
      )}
      maskClosable={false}
      open={visible}
      title={null}
      width={868}
    >
      <div className={styles.modalWrap}>
        <div className={styles.headerTabWrap}>
          <Tabs
            activeKey={tabKey}
            items={TABS}
            onChange={changeTab}
          />
          <div className={styles.closeIcon}>
            <CloseOutlined
              className={styles.closeIcon}
              onClick={closeModal}
            />
          </div>
        </div>
        {tabKey === 'time' && (
          <div className={styles.timeTip}>
            <InfoCircleOutlined style={{
              fontSize: 14,
              color: '#1978ff',
              marginRight: 4,
            }}
            />
            <span>
              按时间段下载订单速度较慢，点击开始下载后，请等待几分钟再查看结果。
            </span>
          </div>
        )}
        <div className={styles.contentWrap}>
          <div className={styles.shopList}>
            <h3>
              选择店铺
            </h3>
            <Input.Search
              onChange={changeSearchValue}
              onPressEnter={onSearch}
              onSearch={onSearch}
              style={{ marginTop: 12 }}
              value={searchValue}
            />
            <div className={styles.gridWrap}>
              {tabKey === 'time' ? (<EgGrid store={gridModel}/>) : (
                <Radio.Group
                  onChange={(e) => {
                    const value = e.target.value;
                    changeRadioShop(value);
                  }}
                  value={radioShopId}
                >
                  <Space
                    direction="vertical"
                  >
                    {
                      shopList && shopList.map((item) => {
                        const {
                          shopName,
                          shopId,
                        } = item;
                        return (
                          <Radio
                            key={shopId}
                            value={shopId}
                          >
                            {shopName}
                          </Radio>
                        );
                      })
                    }
                  </Space>
                </Radio.Group>
              )}
            </div>
          </div>
          <div className={styles.conditionsaWrap}>
            <h3>
              {tabKey === 'time' ? '选择下载时间' : '请输入平台售后单号'}
            </h3>
            <div className={styles.selectWrap}>
              <Form
                ref={formRef}
              >
                {tabKey === 'time' && (
                  <div className={styles.timeSelect}>
                    <Form.Item name="startTime">
                      <DatePicker
                        showTime
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <div>
                      至
                    </div>
                    <Form.Item name="endTime">
                      <DatePicker
                        showTime
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </div>
                )}
                {tabKey === 'order' && (
                  <div className={styles.orderSelect}>
                    <Form.Item name="orderIds">
                      <Input.TextArea
                        className={styles.textArea}
                        placeholder="多个平台单号换行输入，例如&#10;647649376257&#10;648045989743"
                      />
                    </Form.Item>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});
