import { Popover } from 'antd';
import type { FilterItemOptions } from 'egenie-utils';
import { ImgFormatter } from 'egenie-utils';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import { CopyIcon } from '../components';
import type { EachColumnProps, EachHeaderProps } from '../components/orderGrid/store';
import styles from './index.less';
import type { Store } from './store';

// 查询项
export const FILTER_ITEMS = (context: Store): FilterItemOptions[] => {
  return [
    {
      type: 'input',
      field: 'platformRefundCode',
      label: '售后编号',
    },
    {
      type: 'select',
      field: 'shopId',
      label: '店铺名称',
    },
    {
      type: 'select',
      field: 'platformRefundStatus',
      label: '售后状态',
    },
    {
      type: 'input',
      field: 'platformOrderCode',
      label: '订单编号',
    },
    {
      type: 'input',
      field: 'courierRefundNo',
      label: '退回单号',
    },
    {
      type: 'input',
      field: 'skuNo',
      label: 'SKU编码',
    },
    {
      type: 'dateRange',
      field: 'applyTime',
      label: '申请时间',
    },
    {
      type: 'select',
      field: 'afterSaleType',
      label: '售后类型',
    },
    {
      type: 'select',
      field: 'reasonType',
      label: '售后原因',
    },
  ];
};

// 订单表格列信息
export const GRID_COLUMNS = (context: Store): EachColumnProps[] => {
  return [
    {
      width: 10,
      name: '商品信息',
      merge: false,
      detailFormatter: (detail, row) => {
        const { skuPic, title, skuColorType, skuSizeType, skuNo } = detail;
        return (
          <div className={styles.productInfo}>
            {
              skuPic ? (
                <ImgFormatter
                  height={56}
                  value={skuPic}
                  width={56}
                />
              ) : (
                <img
                  className={styles.imgStyle}
                  src={noPic}
                />
              )
            }
            <div className={styles.productText}>
              <div>
                {title || ' '}
              </div>
              <div>
                {skuColorType}
                /
                {skuSizeType}
              </div>
              <div>
                SKU编码：
                {skuNo}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      width: 5,
      name: '金额(元)',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div>
            {detail.money}
          </div>
        );
      },
    },
    {
      width: 5,
      name: '数量',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div>
            {detail.afterSaleCount}
          </div>
        );
      },
    },
    {
      width: 150,
      name: '售后状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            {row.platformRefundStatusDescribe}
          </div>
        );
      },
    },
    {
      width: 150,
      name: '售后原因',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            {row.reasonTypeDescribe}
          </div>
        );
      },
    },
    {
      width: 150,
      name: '物流状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <>
            <div
              className={styles.returnGoods}
            >
              {row.courierRefundNo ? '已发货' : '未发货'}
            </div>
            {row.courierRefundNo && (
              <div
                className={styles.gridLink}
                onClick={() => {
                  context.courierInfoStore.onDrawerOpen(row.afterSaleId, row.courierRefundNo);
                }}
              >
                查看物流
              </div>
            )}
          </>
        );
      },
    },
    {
      width: 150,
      name: '买家信息',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div>
            {row.buyerName}
          </div>
        );
      },
    },
  ];
};

// 每一项的标题头
export const ITEM_HEADER_COLUMNS = (context: Store): EachHeaderProps[] => {
  return [
    {
      width: '20%',
      key: 'platformRefundCode',
      rowFormatter: (row) => {
        return (
          <>
            <div>
              售后编号：
              {row.platformRefundCode}
            </div>
            <CopyIcon text={row.platformRefundCode}/>
          </>
        );
      },
    },
    {
      width: '20%',
      key: 'platformOrderCode',
      rowFormatter: (row) => {
        return (
          <>
            订单编号：
            {
              row.platformOrderCode
            }
          </>
        );
      },
    },
    {
      width: '20%',
      key: 'applyTime',
      rowFormatter: (row) => {
        return (
          <div>
            申请时间：
            {row.applyTime}
          </div>
        );
      },
    },
    {
      width: '20%',
      key: 'type',
      rowFormatter: (row) => {
        return row.platformRefundStatusDescribe ? (
          <div className={styles.afterSaleStatus}>
            {row.platformRefundStatusDescribe}
          </div>
        ) : null;
      },
    },
  ];
};

// 每一项的标题头右边内容
export const itemHeaderRightContent = (row): React.ReactNode => {
  return row.afterSaleSellerMemo ? (
    <Popover
      content={row.afterSaleSellerMemo}
      placement="bottom"
    >
      <a>
        备注
      </a>
    </Popover>
  ) : (
    <a>
      备注
    </a>
  );
};

const AfterSaleTypeDict = {
  '1': '仅退款',
  '2': '退货退款',
};

// 物流状态
const CourierTypeDict = {
  '0': '未发货',
  '1': '已发货',
};

const AfterSaleTypeOptions = [
  {
    label: '仅退款',
    value: '1',
  },
  {
    label: '退货退款',
    value: '2',
  },
];
