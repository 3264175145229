import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { api } from '../api';
import type { ICourierInfo, ICourierTrace, ISkuInfo } from '../interface';
import type { Store } from '../store';

export class CourierInfoStore {
  constructor(options) {
    this.parent = options.parent;
  }

  public parent: Store;

  @observable public visible = false;// 是否展示物流信息

  @observable public loading = false;// 抽屉loading

  public courierName = '';// 物流公司

  public courierOrderNo = '';// 运单号

  public returnSendTime = '';// 发货时间

  public totalNum = 0;// 商品数量

  public skuVoList: ISkuInfo[] = [];// skuList

  public courierInfoList: ICourierTrace[] = [];// 物流信息

  // 关闭抽屉
  @action public onDrawerClose = (): void => {
    Object.assign(this, {
      courierName: '',
      courierOrderNo: '',
      returnSendTime: '',
      totalNum: null,
      skuVoList: [],
      courierInfoList: [],
      visible: false,
      mallReturnOrderId: null,
    });
  };

  // 打开抽屉
  @action public onDrawerOpen = (afterSaleId: number, waybillCode: string): void => {
    this.visible = true;
    this.loading = true;
    request<BaseData<ICourierInfo>>({
      url: api.getCourierInfo,
      method: 'post',
      data: {
        afterSaleId,
        waybillCode,
      },
    }).then((res) => {
      const { courierName, waybillCode, sendTime, totalNum, skuInfoList, courierTraceList } = res.data;
      const skuInfoListTmp = skuInfoList?.map((item) => {
        item.goodsSkuId = item.skuId;
        item.num = item.number;
        item.picUrl = item.skuPic;
        return item;
      });
      Object.assign(this, {
        courierName,
        courierOrderNo: waybillCode,
        returnSendTime: sendTime,
        totalNum,
        skuVoList: skuInfoListTmp,
        courierInfoList: courierTraceList,
      });
    })
      .finally(() => {
        this.loading = false;
      });
  };
}
