import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { EgGridModel, request } from 'egenie-utils';
import { debounce } from 'lodash';
import { observable, action } from 'mobx';
import moment from 'moment';
import React from 'react';
import { api } from '../api';
import type { IShopList } from '../interface';

export default class DownloadOrderModalStore {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public loading = false;

  @observable public visible = false;

  @observable public btnDisabled = false;

  @observable public tabKey = 'time';

  @observable public searchValue = '';

  @observable public radioShopId = null;

  @observable public shopList: IShopList[] = [];

  @observable public originShopList: IShopList[] = [];

  @observable public formRef = React.createRef<FormInstance>();

  @action public onShow = (): void => {
    this.visible = true;
    this.getShopList();
    setTimeout(() => {
      this.formRef?.current?.setFieldsValue({
        startTime: moment().subtract('days', 3),
        endTime: moment(Date.now()),
      });
    });
  };

  @action public closeModal = (): void => {
    this.visible = false;
    this.tabKey = 'time';
  };

  @action public changeTab = (key: string): void => {
    this.tabKey = key;
    this.shopList = this.originShopList;
    this.gridModel.rows = this.shopList;
  };

  @action public changeRadioShop = (value: number): void => {
    this.radioShopId = value;
  };

  @action public onSearch = debounce((): void => {
    this.shopList = this.originShopList.filter((v) => v.shopName.includes(this.searchValue));
    if (this.tabKey === 'time') {
      this.gridModel.rows = this.shopList;
    }
  }, 300);

  @action public changeSearchValue = (e): void => {
    this.searchValue = e.target.value;
    this.onSearch();
  };

  @observable public gridModel = new EgGridModel({
    columns: [
      {
        key: 'shopName',
        name: '店铺',
      },
      {
        key: 'platformTypeDescribe',
        name: '平台类型',
      },
    ].map((el) => {
      return {
        sortable: false,
        resizable: true,
        ...el,
      };
    }),
    showCheckBox: true,
    sortByLocal: false,
    primaryKeyField: 'shopId',
    setColumnsDisplay: false,
    showReset: true,
    showSelectedTotal: true,
    rowHeight: 72,
    showRefresh: false,
    showQuickJumper: false,
    showPager: false,
  });

  @action public getShopList = (params?): void => {
    this.gridModel.loading = true;
    request<BaseData<IShopList[]>>({ url: api.getShopList }).then((res) => {
      this.gridModel.rows = res.data;
      this.originShopList = this.shopList = res.data;
      this.radioShopId = res.data[0]?.shopId;
    })
      .finally(() => {
        this.gridModel.loading = false;
      });
  };

  @action public handleOk = async(): Promise<void> => {
    const { startTime, endTime, orderIds } = await this.formRef.current.validateFields();

    if (this.tabKey === 'time' && !this.gridModel.selectRows.length) {
      return message.warning('请选择一项数据');
    }
    let diffMonth = 0;
    let correctTime = 0;
    const startTimeTmp = startTime ? moment(startTime).format('YYYY-MM-DD') : null;
    const endTimeTmp = endTime ? moment(endTime).format('YYYY-MM-DD') : null;

    if (!startTime || !endTime) {
      message.error('请选择开始时间和结束时间， 间隔不超过三天');
      return;
    }
    if (startTime && endTime) {
      const startDateShould = moment(endTimeTmp).subtract(3, 'days')
        .format('YYYY-MM-DD'); // 三天最小值
      diffMonth = new Date(startDateShould).getTime() - new Date(startTimeTmp).getTime();
      correctTime = new Date(endTimeTmp).getTime() - new Date(startTimeTmp).getTime();
    }

    if (diffMonth > 0) {
      message.error('日期间隔不超过三天');
      return;
    } else if (correctTime < 0) {
      message.error('开始时间不能大于结束时间');
      return;
    }

    if (this.tabKey !== 'time') {
      if (!orderIds?.length) {
        return message.warning('请输入平台单号');
      }
      if (!this.radioShopId) {
        return message.warning('请选择店铺');
      }
      if (orderIds?.split(/\n/).length > 500) {
        return message.warning('平台售后单号不能超过500条');
      }
    }

    this.loading = true;
    try {
      const req = await request({
        url: api.downloadOrder,
        method: 'post',
        data: {
          refundId: this.tabKey === 'time' ? undefined : orderIds.split(/\n/).join(','),
          shopId: this.tabKey === 'time' ? Array.from(this.gridModel.selectedIds).join(',') : this.radioShopId,
          startTime: this.tabKey === 'time' ? moment(startTime)
            .valueOf() : undefined,
          endTime: this.tabKey === 'time' ? moment(endTime)
            .valueOf() : undefined,
        },
      });
      message.success('提交成功，请稍等几分钟再查看结果');
      this.loading = false;
      this.closeModal();
    } catch (error) {
      this.loading = false;
    }
  };
}

