import { Card, Button } from 'antd';
import { NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { TopTab, OrderGrid } from '../components';
import { CourierInfoDrawer } from './courierInfoDrawer';
import { DownloadOrderModal } from './downOrderModal';
import styles from './index.less';
import { Store } from './store';

const store = new Store();

const ShopAfterSaleManage = observer(() => {
  const { programme, afterSaleStatusList, activeStatus, handleTabChange, orderGridStore, courierInfoStore, downloadOrderModalStore } = store;
  return (
    <div className={styles.page}>
      <TopTab
        activeKey={activeStatus}
        list={afterSaleStatusList}
        onTabChange={handleTabChange}
      />
      <Card className={styles.card}>
        <NormalProgrammeComponent store={programme}/>
      </Card>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => {
            downloadOrderModalStore.onShow();
          }}
          size="small"
        >
          手动下载订单
        </Button>
      </div>
      <div className={styles.orderWrapper}>
        <OrderGrid store={orderGridStore}/>
      </div>
      {/* 物流信息抽屉 */}
      <CourierInfoDrawer store={courierInfoStore}/>
      <DownloadOrderModal store={downloadOrderModalStore}/>
    </div>
  );
});

export default ShopAfterSaleManage;
